import * as React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/Layout'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NotFoundPage = () => {
    // Use static query instead of page query, so we can export this whole component and import it in 404.js
    const data = useStaticQuery(
        graphql`
            query {
                heroBg: file(relativePath: { eq: "sirumobile_404.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 3840, placeholder: BLURRED, quality: 80)
                    }
                }
            }
        `
    )

    const bgImage = getImage(data.heroBg)

    return (
        <Layout>
            <NotFoundStyles>
                <SEOTools title='Page not found'>
                    <meta name='robots' content='noindex' />
                </SEOTools>

                <section className='dark-background-overlay text-white position-relative'>
                    <div className='d-flex align-items-center full-screen-jumbo'>
                        <Container>
                            <Row className='justify-content-center text-center'>
                                <Col md='8'>
                                    <h1>404</h1>
                                    <p className='h2'>This is not the webpage you are looking for.</p>
                                </Col>
                            </Row>
                        </Container>
                        <GatsbyImage
                            className='z-index-n1 position-absolute w-100 h-100'
                            alt=''
                            image={bgImage}
                            role='presentation'
                            placeholder='blurred'
                            loading='eager'
                        />
                    </div>
                </section>
            </NotFoundStyles>
        </Layout>
    )
}

const NotFoundStyles = styled.div`
    h1 {
        font-family: 'Open Sans', sans-serif;
        font-size: 6rem;
    }
`

export default NotFoundPage
